import { Injectable } from '@angular/core';
import { PRODUCTION_TENANTS, TENANT } from '@brain-portal/models/config/tenant.config';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private getSubDomainFromUrl = (): string | undefined => {
    const urlParts = window.location.href.match(/^(https?:\/\/)?([^\/]+)(\/.*)?$/);
    return urlParts?.[2]?.match(/^([^.]+)/)?.[1];
  };

  getTenantFromSubDomain() {
    return this.getSubDomainFromUrl();
  }

  isProductionTenant = () => PRODUCTION_TENANTS.includes(this.getTenantFromSubDomain() ?? '');

  getValidTenant() {
    const subDomainTenant = this.getTenantFromSubDomain() ?? '';

    return Object.values(TENANT).includes(subDomainTenant) ? subDomainTenant : TENANT.dev;
  }
}
