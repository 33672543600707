import { AuthMfaInformation } from '@brain-auth/models/auth-mfa-information.model';
import { AuthValidateLoginResponse } from '@brain-auth/models/auth-validate-login-response.model';
import { createAction, props } from '@ngrx/store';

export const initLoginSignIn = createAction('[Login] Init Sign-in');
export const initLoginForgotPassword = createAction('[Login] Init Forgot Password');

export const validateLogin = createAction('[Login] Validate login', props<{ email: string }>());
export const validateLoginSuccess = createAction('[Login] Validate login success', props<{ authValidateLoginResponse: AuthValidateLoginResponse }>());
export const validateLoginError = createAction('[Login] Validate login error', props<{ error: Error }>());

export const postLogin = createAction('[Login] Asking for login', props<{ username: string; password: string }>());
export const postLoginSuccess = createAction('[Login] Asking for login success ');
export const postLoginError = createAction('[Login] Asking for login error', props<{ error: Error }>());

export const askForChallengeSuccess = createAction('[Login] Asking to get MFA Code success ', props<{ authMfaInformation: AuthMfaInformation }>());

export const postLoginMfa = createAction('[Login] Login with MFA Code', props<{ authMfaInformation: AuthMfaInformation }>());
export const postLoginMfaSuccess = createAction('[Login] Login with MFA Code success ', props<{ userAuthenticated: any }>());
export const postLoginMfaError = createAction('[Login] Login with MFA Code error', props<{ error: Error }>());

export const loadUserAuthenticated = createAction('[User] Load User Authenticated');
export const loadUserAuthenticatedSuccess = createAction('[User] Load User Authenticated Success', props<{ userAuthenticated: any }>());
export const loadUserAuthenticatedError = createAction('[User] Load User Authenticated Error', props<{ error: Error }>());

export const postForgotPassword = createAction('[User] Forgot Password', props<{ email: string }>());
export const postForgotPasswordSuccess = createAction('[User] Forgot Password Success');
export const postForgotPasswordError = createAction('[User] Forgot Password Error', props<{ error: Error }>());
