import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadUserAuthenticated } from 'brain-data/store/actions/authentication.action';
import { selectTenantConfig } from 'brain-data/store/selectors/config.selector';
import { selectFacilities, selectFacilityGroups } from 'brain-data/store/selectors/facility.selector';
import { State } from 'reducers';
import { TenantService } from 'shared/services/auths/tenant/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'brain';

  tenantConfig$ = this.store.select(selectTenantConfig);
  facilityGroups$ = this.store.select(selectFacilityGroups);
  facilities$ = this.store.select(selectFacilities);

  isRouterToIncludeSpinner?: boolean;
  logoName?: string;

  constructor(
    public tenantService: TenantService,
    private store: Store<State>,
    private location: Location,
  ) {}

  ngOnInit() {
    this.spinnerOnUrl();
    // The spinner should be displayed for a minimum of 500 ms.
    setTimeout(() => {
      this.store.dispatch(loadUserAuthenticated());
    }, 500);

    this.logoName = this.tenantService.getValidTenant();
  }

  spinnerOnUrl() {
    this.isRouterToIncludeSpinner = this.location.path().includes('/portal');
  }
}
