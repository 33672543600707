import { createReducer, on } from '@ngrx/store';

import { TenantConfig } from 'brain-data/model/config.model';
import { loadTenantConfig, loadTenantConfigError, loadTenantConfigSuccess } from '../actions/config.action';

export default interface ConfigState {
  tenantConfig?: TenantConfig;
  tenantError?: Error;
}

export const initialState: ConfigState = {
  tenantConfig: undefined,
  tenantError: undefined,
};

export const reducer = createReducer(
  initialState,
  on(loadTenantConfig, (state): ConfigState => ({ ...state })),
  on(loadTenantConfigSuccess, (state, { tenantConfig }): ConfigState => ({ ...state, tenantConfig })),
  on(loadTenantConfigError, (state, { error }): ConfigState => ({ ...state, tenantError: error })),
);
