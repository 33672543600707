export const environment = {
  production: true,
  version: '5d1d950d81a3dc73ecc57636311996b5b035cf46',
  googleMapsApiKey: 'AIzaSyAiv8XnKboepzWpLJW_dB6H4glm8WFDzTE',
  weatherApiKey: 'ecd5bb0efd934aea9a5122330232811',
  weatherApiUrl: 'https://api.weatherapi.com/v1',
  apiBaseUrl: '/api',
  apiPublicUrl: '/api/public',
  apiBaseAdminUrl: '/api/admin',
  authUrl: 'https://brainapp.us.auth0.com',
  apiAirQualityUrl: 'https://airquality.googleapis.com/v1',
  authClientId: 'e0jIDxskLX0vlvRbwQhR50XUHozEnui0',
  authGrantType: 'password',
};
