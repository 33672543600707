import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  askForChallengeSuccess,
  loadUserAuthenticated,
  loadUserAuthenticatedError,
  loadUserAuthenticatedSuccess,
  postForgotPassword,
  postForgotPasswordError,
  postForgotPasswordSuccess,
  postLogin,
  postLoginError,
  postLoginMfa,
  postLoginMfaError,
  postLoginMfaSuccess,
  postLoginSuccess,
  validateLogin,
  validateLoginError,
  validateLoginSuccess,
} from '../actions/authentication.action';
import { AuthenticationService } from 'brain-data/service/auth/authentication.service';
import { Router } from '@angular/router';
import { AUTH_ERROR } from 'constants/auth-error.constant';
import { getDashboardlUrl, getMfaUrl } from 'shared/services/utils/url/url.service';
import { AuthErrorResponse } from '@brain-auth/messages/auth-error-response';
import { AuthMfaInformation } from '@brain-auth/models/auth-mfa-information.model';
import { loadFacilitiesAtStartup } from '../actions/facility.actions';
import { AuthValidateLoginResponse } from '@brain-auth/models/auth-validate-login-response.model';

@Injectable()
export class AuthenticationEffects {
  validateLoginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(validateLogin),
      switchMap(({ email }) =>
        this.authenticationService.validateLogin(email).pipe(
          map(authValidateLoginResponse => validateLoginSuccess({ authValidateLoginResponse })),
          catchError(error => of(validateLoginError({ error }))),
        ),
      ),
    );
  });

  postLoginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postLogin),
      switchMap(({ username, password }) =>
        this.authenticationService.validateLogin(username).pipe(
          switchMap((authLoginValidateResponse: AuthValidateLoginResponse) => {
            if (authLoginValidateResponse.canLogin) {
              return this.authenticationService.login(username, password).pipe(
                map(() => postLoginSuccess()),
                tap(() => void this.router.navigate([getDashboardlUrl()])),
                catchError(error => {
                  const err = error.error as AuthErrorResponse;
                  if (err.error === AUTH_ERROR.mfa_required) {
                    return this.handleMFA(err, username);
                  } else {
                    return of(postLoginError({ error }));
                  }
                }),
              );
            } else {
              return of(validateLoginError({ error: new Error('Login validation not allowed') }));
            }
          }),
          catchError(error => of(validateLoginError({ error }))),
        ),
      ),
    );
  });

  private handleMFA(err: AuthErrorResponse, username: string) {
    return this.authenticationService.askForChallenge(err.mfa_token, username).pipe(
      map(authMfaAssociate => {
        return {
          mfaToken: err.mfa_token,
          oobCode: authMfaAssociate.oob_code,
        } as AuthMfaInformation;
      }),
      map(authMfaInformation => askForChallengeSuccess({ authMfaInformation })),
      tap(() => void this.router.navigate([getMfaUrl()])),
      catchError(error => of(postLoginError({ error }))),
    );
  }

  postLoginMfaEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postLoginMfa),
      switchMap(({ authMfaInformation }) =>
        this.authenticationService.loginMfa(authMfaInformation).pipe(
          switchMap(() =>
            this.authenticationService.loadUserProfile().pipe(
              map(userAuthenticated => postLoginMfaSuccess({ userAuthenticated: (userAuthenticated as any).info })),
              map(() => loadFacilitiesAtStartup()),
              tap(() => void this.router.navigate([getDashboardlUrl()])),
            ),
          ),
          catchError(error => of(postLoginMfaError({ error }))),
        ),
      ),
    );
  });

  loadUserAuthenticatedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserAuthenticated),
      map(() => {
        const userAuthenticated = this.authenticationService.userAuthenticated();
        if (userAuthenticated) {
          return loadUserAuthenticatedSuccess({ userAuthenticated });
        }
        return loadUserAuthenticatedError({ error: new Error('no user') });
      }),
    );
  });

  postForgotPasswordEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postForgotPassword),
      switchMap(({ email }) => {
        return this.authenticationService.askForPasswordChange(email).pipe(
          map(() => postForgotPasswordSuccess()),
          catchError(error => of(postForgotPasswordError({ error }))),
        );
      }),
    );
  });

  constructor(
    private router: Router,
    private actions$: Actions,
    private authenticationService: AuthenticationService,
  ) {}
}
