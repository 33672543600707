import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadEuiAndFacilityPerformancesBuildingByFacilityGroupId,
  loadEuiAndFacilityPerformancesBuildingByFacilityGroupIdError,
  loadEuiAndFacilityPerformancesBuildingByFacilityGroupIdSuccess,
  loadFacilities,
  loadFacilitiesAndSetCurrentFacilityById,
  loadFacilitiesAndSetCurrentFacilityByIdSuccess,
  loadFacilitiesAtStartup,
  loadFacilitiesAtStartupError,
  loadFacilitiesAtStartupSuccess,
  loadFacilitiesError,
  loadFacilitiesSuccess,
  loadFacilityById,
  loadFacilityByIdError,
  loadFacilityByIdSuccess,
  loadFacilityGroupEuiForYearAndFacilityGroupId,
  loadFacilityGroupEuiForYearAndFacilityGroupIdError,
  loadFacilityGroupEuiForYearAndFacilityGroupIdSuccess,
  loadFacilityGroups,
  loadFacilityGroupsError,
  loadFacilityGroupsSuccess,
  loadFacilityPerformanceBuildingByFacilityId,
  loadFacilityPerformanceBuildingByFacilityIdError,
  loadFacilityPerformanceBuildingByFacilityIdSuccess,
  loadFacilityPerformancesBuildingByFacilityGroupId,
  loadFacilityPerformancesBuildingByFacilityGroupIdError,
  loadFacilityPerformancesBuildingByFacilityGroupIdSuccess,
  loadFacilityPerformanceSolarByFacilityGroupId,
  loadFacilityPerformanceSolarByFacilityGroupIdError,
  loadFacilityPerformanceSolarByFacilityGroupIdSuccess,
  loadFacilityPerformanceSolarByFacilityId,
  loadFacilityPerformanceSolarByFacilityIdError,
  loadFacilityPerformanceSolarByFacilityIdSuccess,
  setCurrentFacilityById,
  updateFacility,
  updateFacilityError,
  updateFacilityGroup,
  updateFacilityGroupError,
  updateFacilityGroupSuccess,
} from '../actions/facility.actions';
import { catchError, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { FacilityGroupService } from '../../service/facility-group/facility-group.service';
import { FacilityService } from '../../service/facility/facility.service';
import { FacilityPerformanceService } from 'brain-data/service/facility-performance/facility-performance.service';
import { loadUserAuthenticated, postLoginSuccess } from '../actions/authentication.action';
import { FacilityGroup } from 'brain-data/model/facility-group.model';
import { loadTenantConfig } from '../actions/config.action';

@Injectable()
export class FacilityEffects {
  //Facilities

  loadFacilityGroupsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilityGroups),
      mergeMap(() =>
        this.facilityGroupService.getFacilityGroups().pipe(
          map(facilityGroups => loadFacilityGroupsSuccess({ facilityGroups })),
          catchError(error => of(loadFacilityGroupsError({ error }))),
        ),
      ),
    );
  });

  loadFacilitiesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilities),
      switchMap(() =>
        this.facilityService.getFacilities().pipe(
          map(facilities => loadFacilitiesSuccess({ facilities })),
          catchError(error => of(loadFacilitiesError({ error }))),
        ),
      ),
    );
  });

  loadFacilitiesAndSetCurrentFacilityByIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilitiesAndSetCurrentFacilityById),
      switchMap(({ facilityId }) =>
        this.facilityService.getFacilities().pipe(
          map(facilities => loadFacilitiesAndSetCurrentFacilityByIdSuccess({ facilities, facilityId })),
          catchError(error => of(loadFacilitiesError({ error }))),
        ),
      ),
    );
  });

  loadFacilitiesAndSetCurrentFacilityByIdSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilitiesAndSetCurrentFacilityByIdSuccess),
      map(({ facilityId }) => setCurrentFacilityById({ facilityId })),
    );
  });

  loadFacilityByIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilityById),
      mergeMap(({ facilityId }) =>
        this.facilityService.getFacilityById(facilityId).pipe(
          map(facility => loadFacilityByIdSuccess({ facility })),
          catchError(error => of(loadFacilityByIdError({ error }))),
        ),
      ),
    );
  });

  loadFacilityGroupEuiForYearAndFacilityGroupIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilityGroupEuiForYearAndFacilityGroupId),
      switchMap(({ euiYear, facilityGroupId }) =>
        this.facilityGroupService.getFacilityGroupEuiBYFacilityGroupIdAndYear(euiYear, facilityGroupId).pipe(
          map(facilityGroup => loadFacilityGroupEuiForYearAndFacilityGroupIdSuccess({ facilityGroup })),
          catchError(error => of(loadFacilityGroupEuiForYearAndFacilityGroupIdError({ error }))),
        ),
      ),
    );
  });

  loadFacilityPerformanceBuildingByFacilityGroupIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilityPerformancesBuildingByFacilityGroupId),
      switchMap(({ facilityGroupId }) =>
        this.facilityPerformanceService.getFacilityPerformancesBuildingByFacilityGroupId(facilityGroupId).pipe(
          map(facilityGroupPerformance => loadFacilityPerformancesBuildingByFacilityGroupIdSuccess({ facilityGroupPerformance })),
          catchError(error => of(loadFacilityPerformancesBuildingByFacilityGroupIdError({ error }))),
        ),
      ),
    );
  });

  loadEuiAndFacilityPerformanceBuildingByFacilityGroupIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEuiAndFacilityPerformancesBuildingByFacilityGroupId),
      switchMap(({ euiYear, facilityGroupId }) =>
        forkJoin([
          this.facilityGroupService.getFacilityGroupEuiBYFacilityGroupIdAndYear(euiYear, facilityGroupId),
          this.facilityPerformanceService.getFacilityPerformancesBuildingByFacilityGroupId(facilityGroupId),
        ]).pipe(
          map(([facilityGroup, facilityGroupPerformance]) =>
            loadEuiAndFacilityPerformancesBuildingByFacilityGroupIdSuccess({ facilityGroup, facilityGroupPerformance }),
          ),
          catchError(error => of(loadEuiAndFacilityPerformancesBuildingByFacilityGroupIdError({ error }))),
        ),
      ),
    );
  });

  loadFacilityPerformanceBuildingByFacilityIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilityPerformanceBuildingByFacilityId),
      switchMap(({ facilityId }) =>
        this.facilityPerformanceService.getFacilityPerformancesBuildingByFacilityId(facilityId).pipe(
          map(facilityPerformance => loadFacilityPerformanceBuildingByFacilityIdSuccess({ facilityPerformance })),
          catchError(error => of(loadFacilityPerformanceBuildingByFacilityIdError({ error }))),
        ),
      ),
    );
  });

  loadFacilityPerformanceSolarByFacilityGroupIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilityPerformanceSolarByFacilityGroupId),
      switchMap(({ facilityGroupId }) =>
        this.facilityPerformanceService.getFacilityPerformancesSolarByFacilityGroupId(facilityGroupId).pipe(
          map(facilitySolarPerformance => loadFacilityPerformanceSolarByFacilityGroupIdSuccess({ facilitySolarPerformance })),
          catchError(error => of(loadFacilityPerformanceSolarByFacilityGroupIdError({ error }))),
        ),
      ),
    );
  });

  loadFacilityPerformanceSolarByFacilityIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilityPerformanceSolarByFacilityId),
      switchMap(({ facilityId }) =>
        this.facilityPerformanceService.getFacilityPerformancesSolarByFacilityId(facilityId).pipe(
          map(facilitySolarPerformance => loadFacilityPerformanceSolarByFacilityIdSuccess({ facilitySolarPerformance })),
          catchError(error => of(loadFacilityPerformanceSolarByFacilityIdError({ error }))),
        ),
      ),
    );
  });

  updateFacilityGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateFacilityGroup),
      switchMap(({ facilityGroupId, updateFacilityGroupRequest }) =>
        this.facilityGroupService.updateFacilityGroup(facilityGroupId, updateFacilityGroupRequest).pipe(
          map(() =>
            updateFacilityGroupSuccess({
              facilityGroupId,
              updatedFacilityGroup: { id: facilityGroupId, description: updateFacilityGroupRequest.description } as FacilityGroup,
            }),
          ),
          catchError(error => of(updateFacilityGroupError({ error }))),
        ),
      ),
    );
  });

  //Combined Actions
  loadDataAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postLoginSuccess),
      mergeMap(() => of(loadUserAuthenticated(), loadTenantConfig(), loadFacilitiesAtStartup())),
    );
  });

  loadFacilitiesAtStartupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFacilitiesAtStartup),
      switchMap(() =>
        forkJoin([this.facilityService.getFacilities(), this.facilityGroupService.getFacilityGroups()]).pipe(
          map(([facilities, facilityGroups]) =>
            loadFacilitiesAtStartupSuccess({
              facilities,
              facilityGroups,
            }),
          ),
          catchError(error => of(loadFacilitiesAtStartupError({ error }))),
        ),
      ),
    );
  });

  updateFacilityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateFacility),
      switchMap(({ facilityId, facility }) =>
        this.facilityService.updateFacility(facilityId, facility).pipe(
          map(() => loadFacilitiesAndSetCurrentFacilityById({ facilityId })),
          catchError(error => of(updateFacilityError({ error }))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private facilityGroupService: FacilityGroupService,
    private facilityService: FacilityService,
    private facilityPerformanceService: FacilityPerformanceService,
  ) {}
}
