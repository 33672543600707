import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantConfig } from 'brain-data/model/config.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export const TENANT_URL = `${environment.apiBaseUrl}/tenants`;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private http: HttpClient) {}

  getTenantConfig(): Observable<TenantConfig> {
    return this.http.get<TenantConfig>(`${TENANT_URL}/tenant-configs/ui`);
  }
}
