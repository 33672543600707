import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { ConfigService } from 'brain-data/service/config/config.service';
import { loadTenantConfig, loadTenantConfigError, loadTenantConfigSuccess } from '../actions/config.action';

@Injectable()
export class ConfigEffects {
  loadRolesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadTenantConfig),
      switchMap(() =>
        this.configService.getTenantConfig().pipe(
          map(tenantConfig => loadTenantConfigSuccess({ tenantConfig })),
          catchError(error => of(loadTenantConfigError({ error }))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
  ) {}
}
