import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadTenantConfig } from 'brain-data/store/actions/config.action';
import { loadFacilitiesAtStartup } from 'brain-data/store/actions/facility.actions';
import { State } from 'reducers';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private store: Store<State>) {}

  initializeApp() {
    //eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(loadTenantConfig());

    //eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(loadFacilitiesAtStartup());
  }
}
